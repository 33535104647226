import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx/';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx/';
import { NavController, NavParams, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { NetworkService } from 'src/app/services/network/network.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { ITenant } from 'src/app/services/tenant/models/tenant.model';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { IUser } from 'src/app/services/user/models/user.model';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-go',
  templateUrl: './go.page.html',
  styleUrls: ['./go.page.scss'],
})
export class GoPage implements OnInit {

  private unSubscribe: Subject<void> = new Subject<void>();

  private user: IUser = null;
  private tenant: ITenant = null;

  public overlay: boolean = false;
  public idCodeMarkup: boolean = false;
  public gettingIdCode: boolean = true;
  public gettingPinCode: boolean = true;
  public idCode: string = null;
  public idCodeResource: any = null;
  public pinCodeResource: any = null;
  public platformIsIos: boolean = this.platform.is("ios");

  public nav: any;
  public newPinCode: string = '';
  public enterPinCode: boolean = false;
  public pinCodeConflict: boolean = false;
  public invalidPinCode: boolean = false;
  public incompletePinCode: boolean = true;

  public cardCode: string = '';
  public enterCardCode: boolean = false;
  public invalidCardCode: boolean = false;
  public invalidQRCode: boolean = false;
  public incompleteCardCode: boolean = true;
  public cards: Array<any> = [];

  public getTenantAndUserSubscription: Subject<void> = new Subject<void>()
  public isCordova: boolean = this.platformService.isCordova;
  public showTransparentBody: boolean = false;

  public  isInternetAccessAvailable = true;
  public isInternetAvailableSubscription: Subscription = null;

  private logger: Logger;

  constructor(
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private loggingService: LoggingService,
    private navController: NavController,
    private navParams: NavParams,
    private networkService: NetworkService,
    private platform: Platform,
    private platformService: PlatformService,
    private registerCardScannerQR: QRScanner,
    private ref: ChangeDetectorRef,
    private router: Router,
    private storageService: StorageService,
    private tenantService: TenantService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private userService: UserService,
    private vibration: Vibration,
  ) {
    this.logger = loggingService.getLogger("[GoPage]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  ngOnInit() {
  }

  ionViewWillLeave(): void {
		this.isInternetAvailableSubscription.unsubscribe();
    this.invalidQRCode = false;
    this.toggleQRScanningState(false);
  }

  ionViewDidEnter(): void {
    this.storageService.getItemFromKeyValueTable('lastUsedTenant')
    .pipe(takeUntil(this.getTenantAndUserSubscription))
    .subscribe((tenant: ITenantLite) => {
      console.log('tenant', tenant);
      this.logger.info('ionViewDidEnter() ' + 'got last used tenant');
      if (tenant) {
      this.dialogService.showLoadingSpinnerDialog('ionViewDidEnter->refeshCurrentTenant()').subscribe(() => {
        this.tenantService.refreshCurrentTenant(tenant.links.self)
        .pipe(takeUntil(this.getTenantAndUserSubscription))
        .subscribe((tenant) => {
          this.userService.refreshCurrentUser(tenant.links.currentUser)
          .pipe(takeUntil(this.getTenantAndUserSubscription))
          .subscribe((user) => {
            this.dialogService.hideLoadingSpinnerDialog('ionViewDidEnter->refeshCurrentTenant()');
            this.user = user;
            this.tenant = tenant;
            this.getTenantAndUserSubscription.next();
            this.getTenantAndUserSubscription.complete();
            this.setInternetAccessListener();
            this.initPage();
          });
        });
      });
      } else {
        this.authService.logOutUnAuthenticatedUser('ACCESS_DENIED');
      }
    })
  }

  private setInternetAccessListener() {
    this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.isInternetAccessAvailable = isInternetAccessAvailable;
      console.log('isInternetAccessAvailable', isInternetAccessAvailable);
      this.changeDetectorRef.detectChanges();
    });
  }

  private initPage () {
    this.logger.info('initPage()');
    this.user = this.userService.getCurrentUser();
    this.tenant = this.tenantService.tenant;
    this.pinCodeConflict = false;
    this.getIdCode();
    this.getPinCode();
    this.getCards();
    this.checkParams();
  }


  ///////////////////////////////////////////
  ///////////////////////////////////////////
  /////// CHECK PARAMS
  ///////////////////////////////////////////
  private checkParams () {
    this.logger.info('checkParams()');
    let params = location.search;
    // console.log('GO params', params);
    // let urlTree = this.router.parseUrl(this.router.url);
    // let queryParams = urlTree.queryParams;

    // ActivatedRoute
      // this.route.queryParams.subscribe(params => {
      //   this.name = params['name'];
      // });

    // if (queryParams['registrationCode'] && !params.includes['registrationCode']['FORGOT_CODE']) {
    if (params.includes('registrationCode') && !params.includes('registrationCode=FORGOT_CODE')) {
      let splitParams = params.split('&');
      for (let i = 0; i < splitParams.length; i++) {
        if (splitParams[i].includes('registrationCode')) {
          this.cardCode = splitParams[i].split('=')[1];
          if (this.cardCode) {
            this.registerCardWebAppScan();
          }
        }
      }
    } else {
      if (params.includes('registrationCode=FORGOT_CODE')) {
        this.enterCardCode = true;
      }
      // history.pushState({}, null, '/');
    }
  }

  ///////////////////////////////////////////
  ///////////////////////////////////////////
  /////// CARD STUFF
  ///////////////////////////////////////////
  public getCards () {
    this.logger.info('getCards()');
    this.user.api.getCards(this.user).subscribe((cards: any) => {
      if (cards._embedded) {
        this.cards = cards._embedded['px:cards'];
      } else {
        this.cards = [];
      }
    }, (error: any) => {
    });
  }

  public deleteCard (card: any) {
    this.logger.info('deleteCard()');
    this.dialogService.showLoadingSpinnerDialog('deleteCard()').subscribe(() => {
      this.user.api.deleteCard(card).subscribe(() => {
        this.dialogService.hideLoadingSpinnerDialog('deleteCard()');
        let message = this.translateMessageWithParameter('CardDeleted');
        this.toast(message);
        this.getCards();
      }, () => {
        this.dialogService.hideLoadingSpinnerDialog('deleteCard()');
        let message = this.translateMessageWithParameter('ERROR');
        this.toast(message);
      });
    });
  }

  public registerCardDialog () {
    this.logger.info('registerCardDialog()');
    this.enterPinCode = false;
    this.cardCode = '';
    this.invalidCardCode = false;
    this.enterCardCode = !this.enterCardCode;
  }

  public checkCardCode (event) {
    this.logger.info('checkCardCode()');

    this.cardCode = event;
    let numRegex = /^[0-9]\d*$/;
    if (!numRegex.test(this.cardCode)) {
      this.invalidCardCode = true;
    } else {
      this.invalidCardCode = false;
    }

    if (this.cardCode.length < 6) {
      this.incompleteCardCode = true;
    }

    if (this.cardCode.length === 0) {
      this.invalidCardCode = false;
    }

    if (numRegex.test(this.cardCode) && this.cardCode.length === 6) {
      this.invalidCardCode = false;
      this.incompleteCardCode = false;
    }
  }

  public registerCardWebAppScan () {
    this.logger.info('registerCardWebAppScan()');
    this.dialogService.showLoadingSpinnerDialog('registerCardWebAppScan()').subscribe(() => {
      this.enterCardCode = false;
      this.user.api.registerCard(this.user, this.cardCode).subscribe((response) => {
        this.dialogService.hideLoadingSpinnerDialog('registerCardWebAppScan()');
        let message = this.translateMessageWithParameter('CardRegistered');
        this.toast(message);
        this.getCards();
        history.pushState({}, null, '/');
      }, (error) => {
        this.dialogService.hideLoadingSpinnerDialog('ERROR - registerCardWebAppScan()');
        let message = this.translateMessageWithParameter('ERROR');
        this.toast(message);
        history.pushState({}, null, '/');
      });
    });
  }

  public registerCard () {
    this.logger.info('registerCard()');
    this.dialogService.showLoadingSpinnerDialog('registerCard()').subscribe(() => {
      this.enterCardCode = false;
      this.user.api.registerCard(this.user, this.cardCode).subscribe((response) => {
        let message = this.translateMessageWithParameter('CardRegistered');
        this.toast(message);
        setTimeout(() => {
          this.dialogService.hideLoadingSpinnerDialog('registerCard()');
          this.getCards();
        }, 1000);
      }, (error) => {
         this.dialogService.hideLoadingSpinnerDialog('registerCard()');
        let message = this.translateMessageWithParameter('ERROR');
        this.toast(message);
      });
    });
  }

  ///////////////////////////////////////////
  ///////////////////////////////////////////
  /////// ID CODE STUFF
  ///////////////////////////////////////////
  public getIdCode() {
    this.logger.info('getIdCode()');
    this.gettingIdCode = true;
    this.user.api.getIdCode(this.user).subscribe((idCode) => {
      this.idCodeResource = idCode;
      this.gettingIdCode = false;
    }, () => {
      this.gettingIdCode = false;
    });
  }

  public setIdCode() {
    this.logger.info('setIdCode()');
    this.dialogService.showLoadingSpinnerDialog('setIdCode()').subscribe(() => {
      this.user.api.setIdCode(this.tenant).subscribe((idCode) => {
         this.dialogService.hideLoadingSpinnerDialog('setIdCode()');
        this.idCodeResource = idCode;
        let message = this.translateMessageWithParameter('ChangesSaved');
        this.toast(message);
      }, () => {
        this.dialogService.hideLoadingSpinnerDialog('ERROR - setIdCode()');
        let message = this.translateMessageWithParameter('ERROR');
        this.toast(message);
      });
    });
  }

  public resetIdCode() {
    this.logger.info('resetIdCode()');

    this.user.api.deleteIdCode(this.idCodeResource).subscribe((response) => {
      this.setIdCode();
    }, () => {
    });
  }

  public deleteIdCode() {
    this.logger.info('deleteIdCode()');
    this.dialogService.showLoadingSpinnerDialog('deleteIdCode()').subscribe(() => {
      this.user.api.deleteIdCode(this.idCodeResource).subscribe((response) => {
        this.dialogService.hideLoadingSpinnerDialog('deleteIdCode()');
        this.idCodeResource = null;
        let message = this.translateMessageWithParameter('ChangesSaved');
        this.toast(message);
      }, () => {
        this.dialogService.hideLoadingSpinnerDialog('ERROR - deleteIdCode()');
        let message = this.translateMessageWithParameter('ERROR');
        this.toast(message);
      });
    });
  }

  ///////////////////////////////////////////
  ///////////////////////////////////////////
  /////// PIN CODE STUFF
  ///////////////////////////////////////////
  public getPinCode () {
    this.logger.info('getPinCode()');
    this.gettingPinCode = true;
    this.user.api.getPinCode(this.user).subscribe((response) => {
      this.gettingPinCode = false;
      this.pinCodeResource = response;
    }, (response) => {
      this.gettingPinCode = false;
    });
  }

  public toggleEnterPinCode () {
    this.logger.info('toggleEnterPinCode()');

    this.enterPinCode = !this.enterPinCode;
    this.newPinCode = '';
    this.invalidPinCode = false;
    this.enterCardCode = false;
  }

  public checkPinCode (event) {
    this.logger.info('checkPinCode()');

    this.newPinCode = event;
    let numRegex = /^[0-9]\d*$/;
    if (!numRegex.test(this.newPinCode)) {
      this.invalidPinCode = true;
    } else {
      this.invalidPinCode = false;
    }

    if (this.newPinCode.length < 4) {
      this.incompletePinCode = true;
    }

    if (this.newPinCode.length === 0) {
      this.invalidPinCode = false;
    }

    if (numRegex.test(this.newPinCode) && this.newPinCode.length === 4) {
      this.invalidPinCode = false;
      this.incompletePinCode = false;
    }
  }

  public setPinCode () {
    this.logger.info('setPinCode()');
    this.enterPinCode = false;
    this.pinCodeConflict = false;
    this.dialogService.showLoadingSpinnerDialog('setPinCode()').subscribe(() => {
      this.user.api.setPinCode(this.user, this.newPinCode).subscribe((response) => {
        this.dialogService.hideLoadingSpinnerDialog('setPinCode()');
        this.pinCodeResource = response;
        let message = this.translateMessageWithParameter('ChangesSaved');
        this.toast(message);
      }, (httpErrorResponse) => {
        this.dialogService.hideLoadingSpinnerDialog('ERROR - setPinCode()');
        if (httpErrorResponse.status === 409) {
          this.pinCodeConflict = true;
        }
        let message = this.translateMessageWithParameter('ERROR');
        this.toast(message);
      });
    });
  }

  public deletePinCode() {
    this.logger.info('deletePinCode()');
    this.dialogService.showLoadingSpinnerDialog('deletePinCode()').subscribe(() => {
      this.user.api.deletePinCode(this.pinCodeResource).subscribe((response) => {
        this.dialogService.hideLoadingSpinnerDialog('deletePinCode()');
        this.pinCodeResource = null;
        let message = this.translateMessageWithParameter('ChangesSaved');
        this.toast(message);
      }, (response) => {
        this.dialogService.hideLoadingSpinnerDialog('ERROR - deletePinCode()');
        let message = this.translateMessageWithParameter('ERROR');
        this.toast(message);
      });
    });
  }

  ///////////////////////////////////////////
  ///////////////////////////////////////////
  /////// TOAST
  ///////////////////////////////////////////
  private translateMessageWithParameter(text: string, args?: string[]): string {
    if (text) {
      this.translateService.get(text)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((value: string) => {
        text = value;
        this.unSubscribe.next();
        this.unSubscribe.complete();
      });
      return text;
    }
  }

  private toast (message: string) {
    let toast = this.toastController.create({
      message: message,
      duration: 3000,
      position: 'top'
    }).then(toast => toast.present());
  }

  ///////////////////////////////////////////
  ///////////////////////////////////////////
  /////// QR SCANNER STUFF
  ///////////////////////////////////////////

  // button to open camera and scan qr code
  public openQRScanner(): void { // VIEW
    const methodName = 'QR openQRScanner() ';
    this.logger.info(methodName);

    this.registerCardScannerQR.prepare().then((status: QRScannerStatus) => {
      if (status.authorized) {
        this.toggleQRScanningState(true);
        this.scanQRCode();
      } else if (status.denied) {
        // camera permission was permanently denied
        // you must use QRScanner.openSettings() method to guide the user to the settings page
        // then they can grant the permission from there
        this.logger.info(methodName + 'status.denied, the user needs to authorize the app to use the camera from the settings, in order to scan QR codes');
        this.dialogService.showAlertDialog('You need to authorize the app to use the camera from the settings, in order to scan QR codes');
      } else {
        this.logger.info(methodName + 'status.denied, the user needs to authorize the app to use the camera from the settings, in order to scan QR codes');
        this.dialogService.showAlertDialog('You need to authorize the app to use the camera from the settings, in order to scan QR codes');
        // permission was denied, but not permanently. You can ask for permission again at a later time.
      }
    })
    .catch((e: any) => {
      this.logger.info(methodName + 'qrScanner.prepare() ERROR: ', e);
    });
  };

  public toggleQRScanningState(on: boolean): void {
    this.logger.info('toggleQRScanningState()');

    if (on) {
      // window.document.querySelector('ion-app').classList.add('transparentBody');
      // window.document.querySelector('ion-content').classList.add('transparentBody');
      this.registerCardScannerQR.show();
    } else {
      // window.document.querySelector('ion-app').classList.remove('transparentBody');
      // window.document.querySelector('ion-content').classList.remove('transparentBody');
      this.registerCardScannerQR.hide();
      this.registerCardScannerQR.destroy();
    }
    this.showTransparentBody = on;
    this.ref.detectChanges();
  }

  public scanQRCode(): void { // openQRScanner()
    this.logger.info('scanQRCode()');

    this.registerCardScannerQR.scan()
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((qrUrl: string) => {
      this.logger.info('scanQRCode() qrScanner.scan()');
      this.vibration.vibrate(1000);
      this.unSubscribe.next();
      this.unSubscribe.complete();
      this.cardCode = qrUrl.split('=')[1];
      this.cancelQRScanner();
      this.registerCard();
    });
  }

  public cancelQRScanner(): void {
    this.logger.info('cancelQRScanner()');
    setTimeout(() => {
      this.invalidQRCode = false;
      this.toggleQRScanningState(false);
    }, 300);
  };

}
