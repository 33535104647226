import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorService } from '../error/error.service';
import { TenantService } from '../tenant/tenant.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { Logger, LoggingService } from 'ionic-logging-service';

@Injectable({
  providedIn: 'root'
})
export class PasswordService { // for AirPrint

  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private device: Device,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private tenantService: TenantService
    ) {
      this.logger = loggingService.getLogger("[PasswordService]");
      const methodName = "ctor";
      this.logger.entry(methodName);
    }

  public generateAirPrintPassword(): Observable<any> {
    this.logger.info('generateAirPrintPassword()');
    let generatePaswordUrl: string = this.tenantService.tenant.links.airPrintPasswords;
    let deviceUuid = this.device.uuid;
    return new Observable((observer) => {
      this.httpClient.post<any>(generatePaswordUrl, {deviceIdentifier: deviceUuid})
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('generateAirPrintPassword() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[PasswordService] generateAirPrintPassword()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }
}