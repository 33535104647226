import { NgModule } from '@angular/core';
import { OrderByPipe } from './order-by.pipe';
import { PrintJobByPrinterPipe } from './print-job-by-printer.pipe';
import { StringularService } from './stringular.pipe';
// import { RelativeTimePipe } from './relative-time.pipe';


@NgModule({
declarations: [
    OrderByPipe,
    PrintJobByPrinterPipe,
    // RelativeTimePipe,
    StringularService
],
imports: [],
exports: [
    OrderByPipe,
    PrintJobByPrinterPipe,
    StringularService,
    // RelativeTimePipe
],
})

export class PipesModule {static forRoot() {
    return {
        ngModule: PipesModule,
        providers: [
            OrderByPipe,
            PrintJobByPrinterPipe,
            StringularService,
            // RelativeTimePipe
        ]
    };
 }}