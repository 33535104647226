import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor() {}

  // async setLocale(locale: string): Promise<void> {
  //   const formattedLocale = this.formatLocaleString(locale);
  //   try {
  //     await import(`moment/locale/${formattedLocale}`);
  //     moment.locale(formattedLocale);
  //   } catch (error) {
  //     console.error(`Locale ${formattedLocale} not found, falling back to default locale.`);
  //     moment.locale('en'); // Fallback to default locale
  //   }
  // }

  formatLocaleString(locale: string): string {
    switch (locale) {
      case 'no':
        return 'nb';
      case 'jp':
        return 'ja';
      default:
        return locale;
    }
  }

  // formatRelativeTime(date: string | Date): string {
  //   return moment(date).fromNow();
  // }
}