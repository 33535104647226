import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { NavController, NavParams, Platform } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { LoginService } from 'src/app/services/login/login.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { takeUntil } from 'rxjs/operators';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Logger, LoggingService } from 'ionic-logging-service';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { NetworkService } from 'src/app/services/network/network.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  public  authWaiting: boolean = false;
  public  showTenantNameSpinner = false;
  public  buildInAuthFormSubmitted: boolean = false;
  public  buildInAuthWaiting: boolean = false;
  public  buildInAuthUrl: string = null;
  public  errorMessage: string = null;
  private isCordova: boolean = this.platformService.isCordova;
  public  isInternetAccessAvailable = true;
  public isInternetAvailableSubscription: Subscription = null;
  public  jwt: string = null;
  private logData: any = null;
  private logger: Logger;
  public  loginFailed: string = null;
  public  loginMethod: string = null;
  public nav: any;
  private recoverEmailForm: UntypedFormGroup;
  public  recoveringPassword: boolean = false;
  public  resolvingHttpRequest: boolean = false;
  public  tenantList: Array<ITenantLite> = null;
  private unSubscribe: Subject<boolean> = new Subject<boolean>();
  private unSubscribeListenForRedirect: Subject<boolean> = new Subject<boolean>();
  public  userTenantName: string = null;
  public  userInputTenantDomain: string = null;
  public  tenantDomainInput = false;
  public  showTenantDoesNotExist = false;
  public  showTenantIsDeactivated = false;

  private appLink: string;
  private endpointLogInWepAppTenant: string;
  private endpointLogInResponse_WepApp: string;
  private endpointLogInResponse_Native: string;

  // public alternativeOptions: boolean = false;
  // public credentials: { username: string, password: string } = {
  //   username: null,
  //   password: null,
  // };
  public emailSignin: boolean = false;
  // public emailSent: boolean = false;
  // public oneLoginTenantInput = false;
  // public oktaTenantInput = false;

  constructor (
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private changeDetectorRef: ChangeDetectorRef,
    private environmentService: EnvironmentService,
    private errorService: ErrorService,
    private deeplinks: Deeplinks,
    private device: Device,
    private dialogService: DialogService,
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private inAppBrowser: InAppBrowser,
    private loggingService: LoggingService,
    private loginService: LoginService,
    private navController: NavController,
    private navParams: NavParams,
    private networkService: NetworkService,
    public platform: Platform,
    private platformService: PlatformService,
    private route: ActivatedRoute,
    private safariViewController: SafariViewController,
    private tenantService: TenantService
  ) {
    this.logger = loggingService.getLogger("[LoginPage]");
    const methodName = "ctor";
    this.logger.entry(methodName);
    this.errorService.stateError = false;
  }

  ngOnInit() {
  }

  ionViewDidEnter(): void {
    this.logger.info('ionViewDidEnter()');
    this.platform.ready().then(() => {
      if (this.isCordova) {
        this.environmentService.resetEnvironmentEndpoints().subscribe(() => {
          this.errorService.checkServerHealth()
          .pipe(takeUntil(this.unSubscribe))
          .subscribe(() => {
            this.setInternetAccessListener();
            this.initPage();
            this.unSubscribe.next();
            this.unSubscribe.complete();
          }, (httpErrorResponse: HttpErrorResponse) => {
            this.errorService.handleHttpClientResponseError(httpErrorResponse, 'GET', '[LoginPage] onViewDidEnter()');
            this.unSubscribe.next();
            this.unSubscribe.complete();
          });
        });
      } else {
        this.initPage();
      }
    });
  }

  private setInternetAccessListener() {
    this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.isInternetAccessAvailable = isInternetAccessAvailable;
      this.changeDetectorRef.detectChanges();
      // console.log('isInternetAccessAvailable', isInternetAccessAvailable);
    });
  }

  private initPage(): void {
    this.logger.info('initPage()');

    // console.log('this.navParams.data', this.navParams.data);
    // console.log('this.route.queryParams', this.route.queryParams);
    this.route.queryParams
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(params => {
        // this.errorMessage = params && params['URL_PARAM_ERROR'] ? params['URL_PARAM_ERROR'] : '';
        this.errorMessage = '';
    });

    this.dialogService.hideLoadingSpinnerDialog('LoginPage - initPage()');
    // Set error message if there is one. in case of user being unauthorized and therefore logged out
    this.listenForRedirect();
  }

  public listenForRedirect() {
    const methodName = 'listenForRedirect() ';
    this.logger.info(methodName);
    this.deeplinks.route({'' : LoginPage})
    .pipe(takeUntil(this.unSubscribeListenForRedirect))
    .subscribe(match => {
      this.logger.info(methodName + 'deeplinks.route: MATCH ' + match.$link.url.split("code=")[0]);
      // this.dialogService.showSplashScreen('LoginPage - listenForRedirect - splashScreen');
      this.unSubscribeListenForRedirect.next();
      this.unSubscribeListenForRedirect.complete();
      let url: string = match.$link.url;
      this.showTenantNameSpinner = false;
      this.goToHandleSignIn(url);
    }, nomatch => {
      this.logger.info(methodName + 'deeplinks.route: NO_MATCH');
      this.showTenantNameSpinner = false;
    });
  }

  private goToHandleSignIn(url) {
    this.logger.info('goToHandleSignIn()');
    this.loginService.handleOpenUrl(url, this.userInputTenantDomain);
    this.userTenantName = null;
  }

  ionViewWillLeave(): void {
		this.isInternetAvailableSubscription.unsubscribe();
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  public signIn(loginMethod) {
    this.loginMethod = loginMethod;
    this.logger.info('(click) signIn(loginMethod): ' + loginMethod);
    this.broadcaster.broadcast('DISABLE_RESUME');
    this.authWaiting = true;
    let platform: string = '';
    if (loginMethod === 'ms') {
      this.openBrowser(this.environmentService.getEnvironmentEndpoint['endpointLogInMS']);
    } else if (loginMethod === 'google') {
      this.openBrowser(this.environmentService.getEnvironmentEndpoint['endpointLogInGoogle']);
    }
  }

  private openBrowser(endpoint) {
    if (this.isCordova) {
      this.logger.info('signIn() using safariViewController with inAppBrowser as fallback and for web');
      this.appLink = this.environmentService.getEnvironmentEndpoint['appLink'];
      let url: string = endpoint + this.appLink;
      // this.authWaiting = false;
      this.safariViewController.isAvailable()
      .then((available: boolean) => {
        if (available) {
          console.log('## SAFARI_VIEW_CTRL_AVAILABLE');
          this.safariViewController.show({
            url: url
          })
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((response) => {
            this.logger.info('signIn() safariViewController response: ' + response.event);
            // this.authWaiting = false;
            // if (response.event === 'closed') {
            //   this.navController.setRoot(this.navController.getActive().component);
            // }
            this.unSubscribe.next();
            this.unSubscribe.complete();
          });
        } else {
          console.log('## SAFARI_VIEW_CTRL_NOOOOT_AVAILABLE');
          this.logger.info('signIn() safariViewController not available using inAppBrowser to open device browser');
          // fallback to inAppBrowser,
          this.inAppBrowser.create(url, '_system');
          // this.inAppBrowser.create(url);
          // this.authWaiting = false;

        }
      });
    } else {
      this.logger.info('signIn() - WEB');
      let webUrl: string = endpoint + location.origin;
      this.authWaiting = false;
      this.loginMethod = null;
      // this.inAppBrowser.create(webUrl);
      window.open(webUrl, '_self');
    }
  }

  public showInputForTenantDomain(): void {
    this.logger.info('(click) showInputForTenantDomain()');
    this.tenantDomainInput = true;
    // this.navController.push(SetTenantDomainPage);
  }

  public goToSignInApp() {
    const methodName = 'goToSignInApp() ';
    this.logger.info(methodName + 'userTenantName: ' + this.userTenantName);
    this.broadcaster.broadcast('DISABLE_RESUME');
    let signInAppUrl: string;
    if (this.isCordova) {
      this.appLink = this.environmentService.getEnvironmentEndpoint['appLink'];
      this.endpointLogInWepAppTenant = this.environmentService.getEnvironmentEndpoint['endpointLogInWepAppTenant'];
      this.endpointLogInResponse_Native = this.environmentService.getEnvironmentEndpoint['endpointLogInResponse_Native'];

      signInAppUrl = this.endpointLogInWepAppTenant + this.userInputTenantDomain + this.endpointLogInResponse_Native + this.appLink + '?' + this.userInputTenantDomain;
      console.log('signInAppUrl', signInAppUrl);
      this.logger.info(methodName + 'NATIVE signInAppUrl: ' + signInAppUrl);
      // this.inAppBrowser.create(signInAppUrl);

      this.safariViewController.isAvailable()
      .then((available: boolean) => {
        if (available) {
          this.safariViewController.show({
            url: signInAppUrl
          })
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((response) => {
            this.logger.info(methodName, 'safariViewController - SignInApp response: ' + response.event);
            // this.authWaiting = false;
          }, (error) => {
            console.log('safariViewController error', error);
            // this.authWaiting = false;
          });
        } else {
          this.logger.info(methodName, 'safariViewController - not available, using inAppBrowser to open device browser');
          // fallback to inAppBrowser
          this.inAppBrowser.create(signInAppUrl, '_system');
          // this.authWaiting = false;
        }
      });
    } else {
      this.endpointLogInWepAppTenant = this.environmentService.getEnvironmentEndpoint['endpointLogInWepAppTenant'];
      this.endpointLogInResponse_WepApp = this.environmentService.getEnvironmentEndpoint['endpointLogInResponse_WepApp'];
      signInAppUrl = this.endpointLogInWepAppTenant + this.userInputTenantDomain + this.endpointLogInResponse_WepApp + this.userInputTenantDomain;
      this.logger.info(methodName + 'WEB signInAppUrl: ' + signInAppUrl);
      this.authWaiting = false;
      this.loginMethod = null;
      // this.inAppBrowser.create(signInAppUrl);
      window.open(signInAppUrl, '_self');
    }
  }

  private verifyTenantDomain(): string { // for test environments
    if (this.userTenantName.toLowerCase().includes('.printix.dev')) {
      return this.userTenantName.toLowerCase();
    } else {
      return this.userTenantName ? this.userTenantName.toLowerCase() + '.printix.net' : null;
    }
  }

  public checkUserTenant() {
    this.logger.info('(click) checkUserTenant() userTenantName: ' + this.userTenantName);
    this.showTenantNameSpinner = true;
    this.userInputTenantDomain = this.verifyTenantDomain();
    this.logger.info('checkUserTenant() tenantDomain: ' + this.userInputTenantDomain);
    this.environmentService.setEnvironmentVariablesFromUrl(this.userInputTenantDomain)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((response) => {
      this.tenantService.checkIfUserTenantDomainExists(this.userInputTenantDomain)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((response) => {
        this.logger.info('Tenant exists -> goToSignInApp()');
        this.goToSignInApp();
        this.unSubscribe.next();
        this.unSubscribe.complete();
      }, (error) => {
        this.showTenantNameSpinner = false;
        if (error.status === 404) {
          this.showTenantDoesNotExist = true;
        } else if (error.status === 410) {
          this.showTenantIsDeactivated = true;
        }
      });
    });
  }

  public hideErrorText(event) {
    this.showTenantDoesNotExist = false;
    this.showTenantIsDeactivated = false;
    // const inputType = event['inputType'];
    // if (inputType === 'deleteContentBackward' && inputType === 'deleteByCut') {
    // }
  }

  // public showListOfOptions(): void { // VIEW
  // this.logger.info('(click) showListOfOptions()');
  //   this.alternativeOptions = true;
  // }

  // public singInWithEmail(): void {  // VIEW // Old sign in code that uses a webview
  // this.logger.info('(click) signInWithEmail()');
  //   this.emailSignin = true;
  // }

  // public submitEmailCredentials() {  // VIEW
  // this.logger.info('(click) submitEmailCredentials()');
  //   let headers = new HttpHeaders({
  //     'Content-type': 'application/x-www-form-urlencoded'
  //   });

  //   let urlSearchParams = new URLSearchParams();
  //     urlSearchParams.set('grant_type', 'password');
  //     urlSearchParams.set('password', this.credentials.password);
  //     urlSearchParams.set('username', this.credentials.username);
  //     urlSearchParams.set('client_secret', 'thehouseisonfire');
  //     urlSearchParams.set('client_id', 'nativeapp');

  //   const url = environment.endpointToken;
  // this.logger.info('(POST) submitEmailCredentials()');
  //   this.httpClient.post(url, urlSearchParams.toString(), { headers: headers } )
  //   .takeUntil(this.unSubscribe)
  //   .subscribe((response) => {
  //     this.authService.setStorageData(response['refresh_token'], response['access_token'], response['expires_in'])
  //     .takeUntil(this.unSubscribe)
  //     .subscribe(() => {
  //       this.authService.verifyUserCredentials()
  //       .takeUntil(this.unSubscribe)
  //       .subscribe(() => {
  //         this.loginService.getLastUsedTenant();
  //       },
  //       (error: any) => {
  //         if (error) {
  //           this.errorMessage = error;
  //         }
  //         if (this.isCordova) {
  //           this.dialogService.hideSplashScreen();
  //         }
  //       });
  //     });
  //     this.credentials.username = null;
  //     this.credentials.password = null;
  //   }, (httpErrorResponse: HttpErrorResponse) => {
  //     this.errorMessage = 'AUTHN_BAD_CREDENTIALS'; // username or password is wrong
  //     this.errorService.handleHttpClientResponseError(httpErrorResponse, 'POST', '[LoginPage] submitEmailCredentials()');
  //     this.credentials.username = null;
  //     this.credentials.password = null;
  //   });
  // }

  // public recoverPassword (): void { // VIEW
  //   this.recoveringPassword = true;
  //   this.emailSignin = false;
  //   this.recoverEmailFormValidator();
  // }

  // private recoverEmailFormValidator (): void {
  //   this.recoverEmailForm = this.formBuilder.group({
  //     email: ['', [Validators.required, Validators.pattern(this.formValidationService.emailPattern())]],
  //   });
  //   this.formReactions();
  // }

  // private formReactions(): void {
  //   this.recoverEmailForm.get('email').markAsDirty();
  //   this.recoverEmailForm.get('email').valueChanges.debounceTime(1000)
  //   .takeUntil(this.unSubscribe)
  //   .subscribe((value: any) => {
  //     this.errorMessages['email'] = this.formValidationService.setErrorMessage(this.recoverEmailForm.get('email'), 'emailMessages');
  //     if (!value) {
  //       this.errorMessages['email'] = '';
  //       this.recoverEmailForm.get('email').markAsPristine();
  //     }
  //   });
  // }

  // public postRecoverEmail (): void { // VIEW
  //   this.resolvingHttpRequest = true;
  //   let objectToSend: { email: string } = {
  //     email: this.recoverEmailForm.get('email').value
  //   };
  //   this.httpClient.post(environment.endpointRecoverPassword, objectToSend)
  //   .takeUntil(this.unSubscribe)
  //   .subscribe((response) => {
  //     this.emailSent = true;
  //     this.resolvingHttpRequest = false;
  //   }, (error) => {
  //     this.resolvingHttpRequest = false;
  //   });
  // }
}
