import { Component, OnInit, OnDestroy, Pipe, PipeTransform, ChangeDetectorRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { PagingService } from 'src/app/services/paging/paging.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ITenant } from 'src/app/services/tenant/models/tenant.model';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { IUser } from 'src/app/services/user/models/user.model';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { CaptureHistoryService } from 'src/app/services/capture-history/capture-history.service';
import { IScanResource } from 'src/app/services/capture-history/model/IScanResource.model';
import { IPagingInfo } from 'src/app/services/capture-history/model/IPagingInfo.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { CHANNELS, EventService } from 'src/app/services/events/event.service';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { NetworkService } from 'src/app/services/network/network.service';
import { LocaleService } from 'src/app/services/language/locale.service';

@Component({
  selector: 'app-capture-history',
  templateUrl: './capture-history.page.html',
  styleUrls: ['./capture-history.page.scss'],
})
export class CaptureHistoryPage implements OnInit, OnDestroy {

  public appIsActive = true;
  public currentLanguage: string = null;
  public currentPage: number = 0;
  public dataIsLoaded: boolean = false;
  private logger: Logger;
  public nav: any;
  public pagingObject: IPagingInfo = null;
  private pageSize: number = 5;
  private refresher: any = null;
  private resourceId: string = null;
  private resourceFilters: Array<string> = null;
  public signInMethods: {};
  public tenant: ITenant = null;
  public getTenantAndUserSubscription: Subject<void> = new Subject<void>();
  private unSubscribe: Subject<void> = new Subject<void>();
  public unSubscribeInternetAvailability: Subject<void> = new Subject<void>();
  private unSubscribePage: Subject<boolean> = new Subject<boolean>();
  private urlString: string;
  public scanResources: Array<IScanResource> = new Array<IScanResource>();
  public user: IUser = null;
  public isInternetAccessAvailable = true;

  //Pusher Event Subscriptions
  private deletedScanSubscription: Subscription = null;
  private createdScanSubscription: Subscription = null;
  private updatedScanSubscription: Subscription = null;
  public platformResumeEventSubscription: Subscription = null;
  public platformPauseEventSubscription: Subscription = null;
  public platformResumeBroadcastEventSubscription: Subscription = null;
  public isInternetAvailableSubscription: Subscription = null;
  public languageChangeSubscription: Subscription = null;

  constructor(
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private eventService: EventService,
    private localeService: LocaleService,
    private errorService: ErrorService,
    private loggingService: LoggingService,
    private modalCtrl: ModalController,
    private navController: NavController,
    private pagingService: PagingService,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private router: Router,
    private networkService: NetworkService,
    private storageService: StorageService,
    private tenantService: TenantService,
    public translateModule: TranslateModule,
    public translateService: TranslateService,
    public captureHistoryService: CaptureHistoryService,
    private userService: UserService) {
    this.platform.ready().then(() => {
      this.logger = loggingService.getLogger("[CaptureHistoryPage]");
      const methodName = "ctor";
      this.logger.entry(methodName);

      this.signInMethods = {
        LOCAL_AD: 'AD',
        AZURE: 'Microsoft',
        EMAIL: 'Email',
        GOOGLE: 'Google',
        OKTA: 'Okta',
        ONELOGIN: 'OneLogin',
        VERTICAL: 'Vertical',
        client_credentials: 'API',
        KIOSK: 'Kiosk',
        OIDC: 'OIDC'
      };
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void { }

  public async presentInfoModal(event, target, modalType): Promise<void> {

    let dataPackage: any = {
      target: target,
      printer: target,
      cssClass: modalType
    };

    const infoModal = await this.modalCtrl.create({
      component: ModalComponent,
      componentProps: { dataPackage },
      cssClass: modalType
    });

    return await infoModal.present();
  }

  private isCurrentUrlThisPage() {
    let isThisPage = false;
    let currentUrl = null;
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}
    currentUrl = urlTree.toString();
    if (currentUrl && currentUrl === '/capture-history') {
      isThisPage = true;
    };
    return isThisPage;
  }

  public refreshCaptureHistory(refresher?) {
    this.logger.info('refreshCaptureHistory()');
    this.refresher = refresher;

    if (!this.isInternetAccessAvailable && this.refresher) { // prevent refresher from getting stuck, when internet connection is out
      this.refresher.target.complete();
    }
    this.eventService.startEventService();
    this.dataIsLoaded = false;

    this.tenantService.refreshCurrentTenant(this.tenant.links.self)
      .pipe(takeUntil(this.getTenantAndUserSubscription))
      .subscribe((tenant) => {
        this.userService.refreshCurrentUser(tenant.links.currentUser)
          .pipe(takeUntil(this.getTenantAndUserSubscription))
          .subscribe((user) => {
            this.user = user;
            this.tenant = tenant;
            this.getTenantAndUserSubscription.next();
            this.getTenantAndUserSubscription.complete();
            if (this.user && this.user.embedded.user.language) {
              this.currentLanguage = this.localeService.formatLocaleString(this.user.embedded.user.language);
            }
            this.getCaptureHistory();
          });
      });
  }

  public getCaptureHistory() {
    this.logger.info('getCaptureHistory()');
    let currentUserUrl: string = this.tenant.links.currentUser;
    this.dialogService.showLoadingSpinnerDialog('getCaptureHistory()').subscribe(() => {
      this.captureHistoryService.getScanUrl(currentUserUrl)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((scanUrl: string) => {
        this.captureHistoryService.getScanResources(scanUrl, this.currentPage, this.pageSize, 'CREATED', 'DESC')
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((scanResources: Array<IScanResource>) => {
            this.dialogService.hideLoadingSpinnerDialog('getCaptureHistory()');
            this.pagingObject = scanResources["pagingInfo"];
            this.scanResources = scanResources;
            this.dataIsLoaded = true;
            if (this.refresher) {
              this.refresher.target.complete();
            }

            this.unSubscribe.next();
            this.unSubscribe.complete();
            this.handleScanStatus();
          }, (error) => {
            this.dataIsLoaded = true;
            if (this.refresher) {
              this.refresher.target.complete();
            }
            this.dialogService.hideLoadingSpinnerDialog('getCaptureHistory() - ERROR getScanResources');
          });
      }, (error) => {
        this.dataIsLoaded = true;
        if (this.refresher) {
          this.refresher.target.complete();
        }
        this.dialogService.hideLoadingSpinnerDialog('getCaptureHistory() - ERROR - getScanUrl');
      });
    });
  }

  public handleScanStatus() {
    this.logger.info("In handleScanStatus()");
  }

  public getNextPage(showPrevious?) {
    if (showPrevious) {
      this.currentPage--;
    } else {
      this.currentPage++;
    }
    this.refreshCaptureHistory();
  }

  private navigateToPreviousPage() {
    this.navController.pop();
  }

  ionViewWillEnter(): void {
    this.platform.ready().then(() => {
      this.logger.info('ionViewWillEnter()');
      this.storageService.getItemFromKeyValueTable('lastUsedTenant')
        .pipe(takeUntil(this.getTenantAndUserSubscription))
        .subscribe((tenant: ITenantLite) => {
          this.logger.info('ionViewDidEnter() got last used tenant');
          if (tenant) {
            this.tenantService.refreshCurrentTenant(tenant.links.self)
              .pipe(takeUntil(this.getTenantAndUserSubscription))
              .subscribe((tenant) => {
                this.userService.refreshCurrentUser(this.tenantService.tenant.links.currentUser)
                  .pipe(takeUntil(this.getTenantAndUserSubscription))
                  .subscribe((user) => {
                    this.user = user;
                    this.tenant = tenant;
                    this.getTenantAndUserSubscription.next();
                    this.getTenantAndUserSubscription.complete();
                    this.startListeners();
                    this.refreshCaptureHistory();
                  });
              });
          } else {
            this.authService.logOutUnAuthenticatedUser('ACCESS_DENIED');
          }
        });
    });
  }

  ionViewWillLeave(): void {
    this.logger.info('ionViewWillLeave()');
    this.unSubscribe.next();
    this.unSubscribe.complete();
    this.unsubscribePusherMessages();
    this.unsubscribeEvents();
    this.unSubscribeInternetAvailability.next();
    this.unSubscribeInternetAvailability.complete();
  }

  private startListeners(): void {
    const methodName = 'startListener() ';
    this.logger.info(methodName);

    this.createdScanSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.USER_SCAN_CREATED) // get request
      .subscribe(data => {
        this.logger.info('**PUSHER scan created');
        if (this.appIsActive && this.isCurrentUrlThisPage()) {
          this.refreshCaptureHistory();
        }
      });

    this.deletedScanSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.USER_SCAN_DELETED)
      .subscribe(data => {
        this.logger.info('**PUSHER scan removed');
        if (this.appIsActive && this.isCurrentUrlThisPage()) {
          this.refreshCaptureHistory();
        }
      });

    this.updatedScanSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.USER_SCAN_UPDATED)
      .subscribe((data: any) => {
        this.logger.info('**PUSHER scan updated');
        if (this.appIsActive && this.isCurrentUrlThisPage()) {
          this.refreshCaptureHistory();
        }
      });

    this.platformPauseEventSubscription = this.platform.pause.subscribe(async () => {
      this.appIsActive = false;
    });

    this.platformResumeEventSubscription = this.platform.resume.subscribe(async () => {
      this.appIsActive = true;
    });

    this.platformResumeBroadcastEventSubscription = this.broadcaster.on('PLATFORM_RESUMED').subscribe(() => {
      this.logger.info('PLATFORM_RESUMED');
      this.appIsActive = true;
      if (this.isCurrentUrlThisPage()) {
        this.refreshCaptureHistory();
      }
    });

    this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
      .pipe(takeUntil(this.unSubscribeInternetAvailability))
      .subscribe((isInternetAccessAvailable: boolean) => {
        this.isInternetAccessAvailable = isInternetAccessAvailable;
        this.changeDetectorRef.detectChanges();
        if (isInternetAccessAvailable) {
          this.refreshCaptureHistory();
        }
      });

    this.languageChangeSubscription = this.broadcaster.on<string>('languageChange')
    .subscribe(data => {
      this.currentLanguage = this.localeService.formatLocaleString(data);
    });
  }

  private unsubscribePusherMessages(): void {
    this.logger.info('unsubscribePusherMessages()');
    this.createdScanSubscription.unsubscribe();
    this.deletedScanSubscription.unsubscribe();
    this.updatedScanSubscription.unsubscribe();
    this.languageChangeSubscription.unsubscribe();
  }

  private unsubscribeEvents() {
    this.platformResumeEventSubscription.unsubscribe();
    this.platformResumeBroadcastEventSubscription.unsubscribe();
    this.isInternetAvailableSubscription.unsubscribe();
  }
}
