import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NFC, Ndef } from '@awesome-cordova-plugins/nfc/ngx';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx/';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { EventService } from 'src/app/services/events/event.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { NfcService } from 'src/app/services/nfc/nfc.service';
import { PagingService } from 'src/app/services/paging/paging.service';
import { IPrinter } from 'src/app/services/printer/models/printer.model';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { ITenant } from 'src/app/services/tenant/models/tenant.model';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { IUser } from 'src/app/services/user/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

@Component({
  selector: 'app-register-nfc-tag',
  templateUrl: './register-nfc-tag.page.html',
  styleUrls: ['./register-nfc-tag.page.scss'],
})
export class RegisterNfcTagPage implements OnInit {

    private nfcSubscription: Subject<void> = new Subject<void>();
    public showPrinterList = false;
    public showPagination = true;
    public getTenantAndUserSubscription: Subject<void> = new Subject<void>();
    private printerParameters: any;
    private printerUrlString: string;
    private logger: Logger;
    public myInput: string;
    private unSubscribe: Subject<void> = new Subject<void>();
    private unSubscribePage: Subject<boolean> = new Subject<boolean>();
    public printerList: Array<IPrinter> = [];
    private chosenPrinter: IPrinter = null;
    private storedNetworkFilters: Array<any> = [];
    private filterObjectsKey: string = 'filter_objects';
    private dismissedNetwork: Array<string> = [];
    private tenant: ITenant = null;
    private networks: Array<string> = [];
    private nfcReaderMode = null;
    private tenantNetworks: any;
    private loadingPage: boolean = true;
    private user: IUser = null;
    public pagingObject: any = {};
    public currentPage: number = 0;
    private pageSize: number = 8;
    private urlString: string;
    private searchString: string = null;
    private releaseResource: any = null;
    private resourceId: string = null;
    private resourceFilters: Array<string> = null;
    private matchingQueues: string = null;
    private previousSearchString: string = null;
    private previousNetworksFilter: Array<any> = [];
    private componentSubscription: Subject<void> = new Subject<void>();
    private currentLanguage: String = null;
    private currentNetworksPage: number = 0;
    public nav: any;
    private networksPageSize: number = 5;
    private networkSort: string = 'NAME';
    private networkDirection: string = 'ASC';
    private networksEndpoint: string = null;
    private nfcReadyPopover: any;
    private confirmPrinterWithTag: any;
    private scannedTagId: string = null;
    private isScanningNfc: boolean = false;
    public  isInternetAccessAvailable = true;
    public isInternetAvailableSubscription: Subscription = null;

    constructor(
        private authService: AuthService,
        private broadcaster: Broadcaster,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private eventService: EventService,
        private keyboard: Keyboard,
        private loggingService: LoggingService,
        private modalCtrl: ModalController,
        private ndef: Ndef,
        private nfc: NFC,
        private nfcService: NfcService,
        private networkService: NetworkService,
        private pagingService: PagingService,
        public platform: Platform,
        private popoverCtrl: PopoverController,
        private printerService: PrinterService,
        private storageService: StorageService,
        private tenantService: TenantService,
        private userService: UserService,
        private vibration: Vibration,
    ) {
        this.platform.ready().then(() => {
            this.logger = loggingService.getLogger("[RegisterNfcTagPage]");
            const methodName = "ctor";
            this.logger.entry(methodName);

            window.addEventListener('keyboardWillShow', () => {
                console.log("Keyboard will Show");
                this.showPagination = false;
                this.changeDetectorRef.detectChanges();
            });

            window.addEventListener('keyboardDidHide', () => {
                console.log("Keyboard did hide");
                this.showPagination = true;
                this.changeDetectorRef.detectChanges();
            });
        });
    }

    ngOnInit() {
    }

    private setPreventNFCListener() {
        const nfcReaderFlags = this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;

        this.nfc.readerMode(nfcReaderFlags).subscribe(
            (tag) => {
                console.log(tag);
            },
            (err) => {
                console.log('Error reading tag', err);
            }
        );
    }

    ionViewDidEnter(): void {
        this.broadcaster.broadcast('DISABLE_RESUME');
        this.storageService.getItemFromKeyValueTable('lastUsedTenant')
        .pipe(takeUntil(this.getTenantAndUserSubscription))
        .subscribe((tenant: ITenantLite) => {
            this.logger.info('ionViewDidEnter() got last used tenant');
            if (tenant) {
                this.dialogService.showLoadingSpinnerDialog('RegisterNfcTagPage - ionViewDidEnter()').subscribe(() => {
                    this.tenantService.refreshCurrentTenant(tenant.links.self)
                    .pipe(takeUntil(this.getTenantAndUserSubscription))
                    .subscribe((tenant) => {
                        this.userService.refreshCurrentUser(this.tenantService.tenant.links.currentUser)
                        .pipe(takeUntil(this.getTenantAndUserSubscription))
                        .subscribe((user) => {
                            this.user = user;
                            this.tenant = tenant;
                            this.getTenantAndUserSubscription.next();
                            this.getTenantAndUserSubscription.complete();
                            this.tenant = this.tenantService.tenant;
                            this.setInternetAccessListener();
                            this.initiatePrinterList()
                            .pipe(takeUntil(this.unSubscribe))
                            .subscribe((response) => {
                                this.dialogService.hideLoadingSpinnerDialog('RegisterNfcTagPage - ionViewDidEnter()');
                                this.showPrinterList = true;
                                this.unSubscribe.next();
                                this.unSubscribe.complete();
                            });
                        }, () => {
                            this.dialogService.hideLoadingSpinnerDialog('ERROR - RegisterNfcTagPage - ionViewDidEnter() - refreshCurrentUser');
                        }, () => {
                            this.dialogService.hideLoadingSpinnerDialog('ERROR - RegisterNfcTagPage - ionViewDidEnter() - refreshCurrentTenant');
                        });
                    });
                });
            } else {
                this.authService.logOutUnAuthenticatedUser('ACCESS_DENIED');
            }
        });
    }

    private setInternetAccessListener() {
        this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
        .subscribe((isInternetAccessAvailable: boolean) => {
          this.isInternetAccessAvailable = isInternetAccessAvailable;
          this.changeDetectorRef.detectChanges();
          console.log('isInternetAccessAvailable', isInternetAccessAvailable);
        });
    }

    ionViewWillLeave(): void {
		this.isInternetAvailableSubscription.unsubscribe();
        this.broadcaster.broadcast('ENABLE_RESUME');
        this.nfcSubscription.next(); // constructor nfc initialisation subscription
        this.nfcSubscription.complete();
        this.isScanningNfc = false;
        if (this.nfcReaderMode) {
            this.nfcReaderMode.unsubscribe();
            if (this.platform.is('android')) {
                this.setPreventNFCListener();
            }
        }
    }

    private initiatePrinterList(): Observable<void> {
        this.logger.info('initiatePrintersList()');
        return new Observable((observer: Subscriber<void>) => {
            this.eventService.startTokenExpireCountDown();
            this.currentLanguage = this.userService.user.embedded.user.language;
            this.getPrintersList() // 4. get list of printers for selection of printer to associate to scanned nfc tag
            .subscribe((response) => {
                observer.next();
                observer.complete();
            });
            // this.getTenantNetworks() // 1. get tenant networks
            // .subscribe((response) => {
            //     this.getStoredNetworkFilters() // 2. get stored network filters
            //     .subscribe((response) => {
            //         this.getStoredNetworks() // 3. get stored networks on tenant
            //         .subscribe((response) => {

            //         });
            //     });
            // });
        });
    }

    private getTenantNetworks(): Observable<void> {
        this.logger.info('getTenantNetworks()');
        return new Observable((observer: Subscriber<void>) => {
            this.networksEndpoint = this.tenant.links.networks;
            this.tenantService.getTenantNetworks(this.networksEndpoint, [], this.currentNetworksPage, this.networksPageSize, this.networkSort, this.networkDirection, null, null)
            .subscribe((networksList) => {
                this.tenantNetworks = networksList;
                observer.next();
                observer.complete();
            });
        });
    }

    private getStoredNetworkFilters(): Observable<void> {
        this.logger.info('getStoredNetworkFilters()');
        return new Observable((observer: Subscriber<void>) => {
              this.storageService.getItemFromKeyValueTable(this.filterObjectsKey)
              .subscribe((storedNetworkFilters: any) => {
                this.storedNetworkFilters = storedNetworkFilters ? storedNetworkFilters : [];
                observer.next();
                observer.complete();
              }, (error: any) => {
                observer.complete();
              });
        });
    }

    private getStoredNetworks(): Observable<void> {
        this.logger.info('getStoredNetworks()');
        return new Observable((observer: Subscriber<void>) => {
            if (this.storedNetworkFilters.length > 0) {
                let storedNetworkIds: Array<string> = [];
                this.storedNetworkFilters.forEach(nw => {
                  storedNetworkIds.push(nw.id);
                });
                let pageSize = this.storedNetworkFilters.length;
                this.tenantService.getTenantNetworks(this.networksEndpoint, storedNetworkIds, this.currentNetworksPage, pageSize, this.networkSort, this.networkDirection, null, null)
                .subscribe((storedNetworksList) => {
                    let pagingObjectIndex: number = storedNetworksList.length -1;
                    storedNetworksList.splice(pagingObjectIndex, 1);
                    this.storedNetworkFilters = [];
                    for (let i = 0; i < storedNetworksList.length; i++) {
                        let network: Object = {
                            name: storedNetworksList[i].name,
                            id: storedNetworksList[i].links.self,
                            checked: true
                        };
                        this.storedNetworkFilters.push(network);
                    }
                    observer.next();
                    observer.complete();
                }, (error: any) => {
                    observer.complete();
                });
            } else {
                this.storedNetworkFilters = [];
                observer.next();
                observer.complete();
            }
        });
    }

    private getPrintersList(): Observable<void> {
        this.logger.info('getPrintersList()');
        if (this.searchString !== null && this.previousSearchString !== this.searchString) { this.currentPage = 0; }
        this.previousSearchString = this.searchString;

        this.printerParameters = this.pagingService.getPrinterParameters(this.searchString);
        this.printerUrlString = this.pagingService.getUrlString(this.currentPage, this.pageSize);

        this.printerParameters.printers = [];
        this.printerParameters.signIds = [];
        this.printerParameters.types = ['NETWORK', 'VIRTUAL'];

        return new Observable<void>((observer: Subscriber<void>) => {
            this.printerService.getPrinterList(this.printerParameters, this.printerUrlString)
            .subscribe((printerList: Array<IPrinter>) => {
                this.pagingObject = printerList.pop()['page'];
                this.printerList = printerList;
                observer.next();
                observer.complete();
            });
        });
    }

    private template(): Observable<void> {
        return new Observable((observer: Subscriber<void>) => {

        });
    }

    public async choosePrinterToRegister(printer: IPrinter): Promise<void> {
        this.logger.info('choosePrinterToRegister()');
        this.chosenPrinter = printer;
        // this.showPrinterList = false;

        let dataPackage: any = {
            type: 'confirmPrinterWithTag',
            PrinterSignId: printer.signId
        };

        this.confirmPrinterWithTag = await this.popoverCtrl.create({
            component: PopoverComponent,
            cssClass: 'confirm-tag-printer',
            componentProps: {dataPackage},
            backdropDismiss: false
        })

        await this.confirmPrinterWithTag.present();

        await this.confirmPrinterWithTag.onDidDismiss()
        .then((popoverData) => {
            if (popoverData.data === 'registerTag') {
                this.enableNfcScan();
            } else {
                this.showPrinterList = true;
            }
        });
    }

    private enableNfcScan(): void {
        const methodName = 'enableNfcScan() ';
        this.logger.info(methodName);
        this.isScanningNfc = true;

        if (this.platform.is('android')) {
            console.log('## PlatformIsAndroid');
            this.scanWithAndroid();
        } else if (this.platform.is('iphone')) {
            this.scanTagWithIOS('scanTag')
            .subscribe((event) => {
                this.prepareScannedData(event);
            }, (error) => {
                // this.nfcListenerSubscription.unsubscribe();
                this.logger.error(methodName + 'NFC scanTag() ERROR: ' + JSON.stringify(error));
                this.logger.info(methodName + 'Trying scanNdef() method');
                this.scanTagWithIOS('scanNdef')
                .subscribe((event) => {
                    this.prepareScannedData(event);
                }, (error) => {
                    this.logger.info(methodName + 'NFC scanNdef() ERROR: ' + JSON.stringify(error));
                    this.logger.error(methodName + 'Tag not eligible for scanning');
                    this.dialogService.showTranslatedMessage('TagNotEligible');
                });
            });
        }
    }

    private scanTagWithIOS(scanMethod): Observable<any> {
        const methodName = 'scanTagWithIOS() ';
        this.logger.info(methodName);
        return new Observable((observer: Subscriber<any>) => {
            if (scanMethod === 'scanNdef') {
                this.nfc.scanNdef().then((event) => {
                    this.logger.info(methodName + 'Scanned Ndef success');
                    observer.next(event)
                    observer.complete();
                }, (error) => {
                    const errorString = error.toLowerCase();
                    if (errorString === 'session invalidated by user') {
                        this.logger.info(methodName + 'Scan canceled by user');
                        observer.complete();
                    } else {
                        this.logger.info(methodName + 'Scanned Ndef failure');
                        observer.error(error);
                        observer.complete();
                    }
                });
            } else if (scanMethod === 'scanTag') {
                this.nfc.scanTag().then((event) => {
                    this.logger.info(methodName + 'Scanned Tag success');
                    observer.next(event)
                    observer.complete();
                }, (error) => {
                    const errorString = error.toLowerCase();
                    if (errorString === 'session invalidated by user') {
                        this.logger.info(methodName + 'Scan canceled by user');
                        observer.complete();
                    } else {
                        this.logger.info(methodName + 'Scanned Tag failure');
                        observer.error(error);
                        observer.complete();
                    }
                });
            }
        });
    }

    private async scanWithAndroid() {
        console.log('## scanWithAndroid()');
        this.showScanReadyMessageAndroid();

        const nfcReaderFlags = this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;

        // handle Android scan
        this.nfcReaderMode = this.nfc.readerMode(nfcReaderFlags).subscribe(
        (tag) => {
            console.log(tag);
            this.prepareScannedData(tag);
        },
        (err) => {
            console.log('Error reading tag', err);
            this.nfcReaderMode.unsubscribe();
        });
    }

    private async showScanReadyMessageAndroid() {
        let dataPackage: any = {
            type: 'scanNfcReadyNotice'
        };

        this.nfcReadyPopover = await this.popoverCtrl.create({
            component: PopoverComponent,
            cssClass: 'scan-nfc-ready',
            backdropDismiss: false,
            componentProps: {dataPackage}
        });

        await this.nfcReadyPopover.present();

        await this.nfcReadyPopover.onDidDismiss()
        .then((popoverData) => {
            if (popoverData.data === 'cancel') {
                this.isScanningNfc = false;
                this.showPrinterList = true;
                // this.nfcListenerSubscription.unsubscribe();
            }
        });
    }

    private prepareScannedData(event) {
        console.log('## prepareScannedData()');
        const methodName = 'prepareScannedData() ';
        this.logger.info(methodName);
        this.vibration.vibrate(500);
        this.dialogService.showLoadingSpinnerDialog(methodName).subscribe(() => {
            if (this.platform.is('android')) {
                this.nfcReadyPopover.dismiss();
            }

            this.logger.info(methodName + JSON.stringify(event));

            this.getTagId(event);
            this.nfcReaderMode.unsubscribe();
            if (this.platform.is('android')) {
                this.setPreventNFCListener();
            }
        });
    }

    private getTagId(event) {
        console.log('## getTagId() event ##', event);

        const methodName = 'getTagId() ';
        this.logger.info(methodName);
        // alert('scanEvent' + JSON.stringify(event));
        const tagId = event['id'];
        this.logger.info(methodName + 'tagId: ' + tagId);

        if (tagId) {
            this.scannedTagId = this.nfc.bytesToHexString(tagId);
            console.log('this.scannedTagId', this.scannedTagId);
            this.logger.info(methodName + '#### scannedTagId: ' + this.scannedTagId);
            this.registerNfcTagWithChosenPrinter();
        } else {
            this.dialogService.hideLoadingSpinnerDialog('prepareScannedData() - getTagId()');
            this.dialogService.showAlertDialog('Scan method cannot retrieve tag-id, tag cannot be registered');
        }
    }

    private registerNfcTagWithChosenPrinter() {
        this.logger.info('registerNfcTagWithChosenPrinter()');
        let registerUrl: string = this.chosenPrinter.links.registerNfcTag;
        this.nfcService.registerTagWithPrinter(registerUrl, this.scannedTagId)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((response) => {
            this.dialogService.hideLoadingSpinnerDialog('registerNfcTagWithChosenPrinter() - registerTagWithPrinter')
            this.isScanningNfc = false;
            this.showPrinterList = true;
            this.unSubscribe.complete();
            this.dialogService.showTranslatedMessage('Done');
        }, (error) => {
                this.isScanningNfc = false;
                this.showPrinterList = true;
                this.unSubscribe.complete();
                if (error.status === 409) {
                    this.nfcService.getPrinterFromTagId(this.scannedTagId)
                    .pipe(takeUntil(this.unSubscribe))
                    .subscribe((scannedPrinter: IPrinter) => {
                        this.dialogService.hideLoadingSpinnerDialog('registerNfcTagWithChosenPrinter() - getPrinterFromTagId - PrinterIdAlreadyRegistered');
                        this.dialogService.showTranslatedMessage('PrinterIdAlreadyRegistered', scannedPrinter.signId);
                    }, (error => {
                        this.dialogService.hideLoadingSpinnerDialog('ERROR registerNfcTagWithChosenPrinter() - getPrinterFromTagId');
                        this.logger.info('registerNfcTagWithChosenPrinter() getPrinterFromTagId() ERROR: ' + JSON.stringify(error));
                    }));
                } else {
                    this.dialogService.hideLoadingSpinnerDialog('ERROR registerNfcTagWithChosenPrinter() - registerTagWithPrinter');
                    this.logger.info('registerNfcTagWithChosenPrinter() registerTagWithPrinter() ERROR' + JSON.stringify(error));
                    this.dialogService.showAlertDialog('Register tag to server, error: ' + error);
                }
        });
    }

    // private getTagData(event) {
    //     const tagData = this.platform.is('android') ? event.tag['ndefMessage']
    //     : this.platform.is('iphone') ? event['ndefMessage']
    //     : null;
    //     this.logger.info('getTagData() tagData: ' + JSON.stringify(tagData));
    //     return tagData;
    // }

    // private handleScannedTag(tagData) {
    //     this.logger.info('handleScannedTag()')
    //     if (this.tagHasRecordedId(tagData)) {
    //         this.logger.info('Tag-Do-HaveRecordedID');
    //         this.registerNfcTagWithChosenPrinter()
    //         .pipe(takeUntil(this.unSubscribe))
    //         .subscribe((response) => {
    //             this.nfcListenerSubscription.unsubscribe();
    //             this.isScanningNfc = false;
    //             this.unSubscribe.next();
    //             this.unSubscribe.complete();
    //             this.showPrinterList = true;
    //             this.dialogService.showTranslatedMessage('Done');
    //         });
    //     } else {
    //         this.logger.info('tagDoes-NOT-HaveRecordedID');
    //         this.writeIdToNfcTag(this.scannedTagId)
    //         .pipe(takeUntil(this.unSubscribe))
    //         .subscribe((response) => {
    //             this.registerNfcTagWithChosenPrinter()
    //             .pipe(takeUntil(this.unSubscribe))
    //             .subscribe((response) => {
    //                 this.makeNfcTagReadOnly()
    //                 .pipe(takeUntil(this.unSubscribe))
    //                 .subscribe((response) => {
    //                     this.nfcListenerSubscription.unsubscribe();
    //                     this.isScanningNfc = false;
    //                     this.unSubscribe.next();
    //                     this.unSubscribe.complete();
    //                     this.showPrinterList = true;
    //                     this.dialogService.showTranslatedMessage('Done');
    //                 });
    //             });
    //         });
    //     }
    // }


    // private tagHasRecordedId(tagData): boolean {
    //     this.logger.info('check for tagHasRecordedId()');
    //     let hasId: boolean = false;
    //     if (tagData) {
    //         let incomingNfcData = this.nfc.bytesToString(tagData[0].payload);
    //         let recordedNfcId: string = incomingNfcData.split('n')[1];
    //         if (recordedNfcId === this.scannedTagId) {
    //             return true;
    //         }
    //     }

    //     return hasId;
    // }

    public presentModal(event: string, target): void {
        this.logger.info('(click) presentModal()');
        let currentPrinterUrl = target.links.self;
        let printerParameters = this.pagingService.getPrinterParameters();
        this.urlString = this.pagingService.getUrlString(0, 5);
        printerParameters.printers = [];
        printerParameters.printers.push(currentPrinterUrl);

        this.printerService.getPrinterList(printerParameters, this.urlString)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe( async (printer: Array<IPrinter>) => {
            this.unSubscribe.next();
            this.unSubscribe.complete();

            let dataPackage = {
                printer: printer[0],
                cssClass: event
            }

            const infoModal = await this.modalCtrl.create({
                component: ModalComponent,
                componentProps: {dataPackage},
                cssClass: event
            });

            return await infoModal.present();
        });
    }

    public onSearch(event, searchInput) {
        this.searchString = searchInput;
        this.refreshPrinterList();
    }

    // public onBlur(searchInput) {
    //     console.log('## onBlur() ##');
    //     console.log('searchInput', searchInput);
    //     this.searchString = searchInput;
    //     this.refreshPrinterList();
    // }

    public getNextPage(showPrevious?) {
        if (showPrevious) {
          this.currentPage--;
        } else {
          this.currentPage++;
        }
        this.refreshPrinterList();
    }

    private refreshPrinterList(): void {
        this.logger.info('refreshPrinterList()');
        this.dialogService.showLoadingSpinnerDialog('refreshPrinterList()').subscribe(() => {
            this.initiatePrinterList()
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((response) => {
                this.dialogService.hideLoadingSpinnerDialog('refreshPrinterList()');
                this.unSubscribe.next();
                this.unSubscribe.complete();
            });
        });
    }

    // private writeIdToNfcTag(id: string): Observable<void> {

    //     let message: any = [
    //         this.ndef.textRecord('writing to NFC tag')
    //     ];

    //     this.logger.info('writeIdToNfcTag() - id: ' + id + ' - message: ' + message);

    //     console.log('message', message);
    //     console.log('typeof message', typeof message);

    //     return new Observable<void>((observer: Subscriber<void>) => {
    //         this.nfc.write(message).then(() => {
    //             observer.next();
    //             observer.complete();
    //         }, (error) => {
    //             this.logger.info('writeIdToNfcTag()  ERROR: ' + JSON.stringify(error));
    //             this.nfcListenerSubscription.unsubscribe();
    //             this.dialogService.showAlertDialog('write tag, error: ' + error);
    //             this.isScanningNfc = false;
    //             this.showPrinterList = true;
    //         });
    //     });
    // }

    // private makeNfcTagReadOnly(): Observable<void> {
    //     this.logger.info('makeNfcTagReadOnly()');
    //     return new Observable<void>((observer: Subscriber<void>) => {
    //         this.nfc.makeReadOnly().then(() => {
    //             observer.next();
    //             observer.complete();
    //         }, (error) => {
    //             this.logger.info('writeIdToNfcTag() makeNfcTagReadOnly() ERROR: ' + JSON.stringify(error));
    //             this.nfcListenerSubscription.unsubscribe();
    //             this.dialogService.showAlertDialog('make read only, error: ' + error);
    //             this.isScanningNfc = false;
    //             this.showPrinterList = true;
    //         });
    //     });
    // }

// public presentFilters(myEvent, popoverType: string): void {
//     let dataPackage: any = {
//       type: popoverType,
//       networks: this.tenantNetworks,
//       resourceId: this.resourceId,
//       storedNetworks: this.storedNetworkFilters
//     };

//     let popover = this.popoverCtrl.create(PopoverComponent, dataPackage, {cssClass: popoverType});
//     popover.present({
//       ev: myEvent
//     });

//     popover.onDidDismiss((chosenFilters) => {
//         if (chosenFilters === 'clearAllFilters') {
//             this.storageService.removeItemFromKeyValueTable(this.filterObjectsKey)
//             .takeUntil(this.unSubscribe)
//             .subscribe(()=> {
//                 this.unSubscribe.next();
//                 this.unSubscribe.complete();
//                 this.refreshPrinterList();
//             });
//         } else if (chosenFilters === 'showNetworksModal') {
//             this.presentNetworksListModal();
//         }
//     });
// }

// private presentNetworksListModal(): void {
//     let dataPackage: any = {
//         networks: this.tenantNetworks,
//         resourceId: this.resourceId,
//         storedNetworks: this.storedNetworkFilters
//     };

//     let infoModal = this.modalCtrl.create(ModalComponent, dataPackage, {cssClass: 'networksList'});
//     infoModal.present();
//     infoModal.onDidDismiss((value) => {
//         this.refreshPrinterList();
//     });
// }

}