<ion-content [ngClass]="{'transparentBody': showTransparentBody}">
    <app-header
      [iconName]="'menu'"
      [nav]="nav"
      [title]="null"
      [menuToggleRight]="true"
      [showErrorMessageNetwork]="true"
      [showErrorMessageHealth]="true"
      [allowHeartDisplay]="true"
      [hidden]="showTransparentBody">
    </app-header>
  <div class="error-network" *ngIf="!isInternetAccessAvailable">
    <span class="error-network-container">
      <i class="icon-warning"></i>
      <span>{{"NoAccessInternet" | translate}}</span>
    </span>
  </div>
  <!-- <ion-refresher id="refresher" [pullFactor]="0.5" [pullMin]="100" [pullMax]="200" (ionRefresh)="refreshPrintJobList($event)" slot="fixed">
    <ion-refresher-content refreshingSpinner="null" pullingIcon="null"></ion-refresher-content> -->
  <ion-refresher id="refresher" (ionRefresh)="refreshPrintJobList($event)" slot="fixed" *ngIf="!showTransparentBody">
    <!-- <ion-refresher-content refreshing-spinner="null"></ion-refresher-content> -->
  </ion-refresher>

  <div class="top-content" *ngIf="!showTransparentBody">
    <div class="greetings-container">
      <div class="greetings" *ngIf="user && user.name">
        <div *ngIf="user.embedded.authentication_details && user.embedded.authentication_details.source">
          <div ng-class="{'hide-greeting': path !== '/print'}">{{'SignedInWithAs'| translate | stringular: user.name : (signInMethods[user.embedded.authentication_details.source] | translate)}}</div>
        </div>
        <div *ngIf="!user.embedded.authentication_details || !user.embedded.authentication_details.source">
          <div ng-class="{'hide-greeting': path !== '/print'}">{{'SignedInAs'| translate | stringular: user.name}}</div>
        </div>
      </div>
      <div class="greetings" *ngIf="tenant && tenant.tenantData.hostingDomain">
        <div ng-class="{'hide-greeting': path !== '/print'}">{{tenant.tenantData.hostingDomain}}</div>
      </div>
    </div>
    <div class="action-button-container">
      <div class="top-action-buttons">
        <button class="size120 mr10 page-action-button delete"
          ion-button
          icon-only
          large
          item-end
          [hidden]="this.debouncerRunning"
          (click)="deleteSelectedPrintJobs()"
          *ngIf="selectedPrintJobs.length > 0">
            <i class="icon-delete"></i>
        </button>
        <button class="mr10 size120 page-action-button"
          [ngClass]="{'disabled': printJobList.length === 0}"
          ion-button
          icon-only
          large
          item-end
          [disabled]="printJobList.length === 0 || debouncerRunning || securePrintLevel === 'NFC' || securePrintLevel === 'ON_DEVICE_RELEASE'"
          *ngIf="isCordova && securePrintMethods.indexOf('QR') !== -1"
          (click)="openQRScanner()">
          <i class="icon-qr-code-2"></i>
        </button>
        <button class="mr10 size120 page-action-button"
          [ngClass]="{'disabled': printJobList.length === 0}"
          ion-button
          icon-only
          large
          item-end
          [disabled]="printJobList.length === 0 || debouncerRunning || securePrintLevel === 'ON_DEVICE_RELEASE'"
          *ngIf="nfcEnabled && securePrintMethods.indexOf('NFC') !== -1"
          (click)="activateNfc()">
          <i class="icon-nfc-chip"></i>
        </button>
        <button class="mr10 size120 page-action-button refresh-button"
          ion-button
          icon-only
          large
          item-end
          (click)="refreshPrintJobList()">
          <i class="icon-refresh"></i>
        </button>
        <button class="mr10 size120 page-action-button"
          ion-button
          icon-only
          large
          item-end
          (click)="showOtherPrintJobs()">
          <i class="icon-clock" [ngClass]="{'icon-clock-solid': printJobsPageType === 'RecentPrintJobsPage'}"></i>
        </button>
        <button class="size120 page-action-button"
          ion-button
          no-margin-horizontal
          icon-only
          large
          item-end
          *ngIf="platform.is('hybrid')"
          [disabled]="!isInternetAccessAvailable"
          (click)="goToCapturePage()">
          <i class="icon-capture"></i>
        </button>
        <!-- <button class="size120 page-action-button"
          ion-button
          no-margin-horizontal
          icon-only
          large
          item-end
          (click)="openQRScanner()">
          <i class="icon-folder-plus"></i>
        </button> -->
      </div>
    </div>
  </div>

  <div class="empty-state pl20 pr20 height100" *ngIf="dataIsLoaded && printerList.length < 1 && !scanning">
    <div *ngIf="printJobsPageType === 'PrintJobsPage'">
      <img src="https://assets.printix.net/img/v2/illustrations/no-print-jobs.svg" alt="no printjobs image">
      <div class="loader loader--sm loader--compact"><span></span></div>
      <h2 class="empty-state__heading ng-binding">
        {{'DocsNone' | translate}}
      </h2>
      <p class="empty-state__description ng-binding">
        {{'NewDocumentsWillAppearAutomatically' | translate }}
      </p>
    </div>
    <div *ngIf="printJobsPageType !== 'PrintJobsPage'" >
        <img src="https://assets.printix.net/img/v2/illustrations/no-recent-print-jobs.svg" alt="no printjobs image">
        <div class="loader loader--sm loader--compact"><span></span></div>
        <h2 class="empty-state__heading ng-binding">
          {{'DocsRecentNone' | translate}}
        </h2>
        <p class="empty-state__description ng-binding">
          {{'DocsRecentNoneDescription' | translate }}
        </p>
    </div>
  </div>
  <!-- Printer -->
  <div class="print__content pt0 pr20 pb100 pl20" [ngClass]="{'none' : showTransparentBody}">
    <div class="list list--boxed list--spaced"
    [ngClass]="{'list--disabled': !printer.options.allowToPrint}"
    *ngFor="let printer of printerList | orderBy : 'signId'">

    <div class="list__heading">
        <div class="relative list__heading__content">

          <span class="list__heading__title">
            <div class="print__printer-id ml5"
              *ngIf="printer.type !== 'FREEDOM'"
              (click)="presentModal($event, printer, 'printerInfo', false, null)"
              tappable>
              <div>{{printer.signId}}</div>
              <div>
                <!-- printer status dots -->
                <span class="printer-status ml10"
                [ngClass]="{'printer-status--online': printer.embedded.status.state === 'READY',
                            'printer-status--warning': printer.embedded.status.state === 'WARNING',
                            'printer-status--offline': printer.embedded.status.state === 'ERROR',
                            'printer-status--undefined': printer.embedded.status.state === 'UNAVAILABLE'}">
                </span>

                <!--  <span class="printer-status ml10"
                [ngClass]="{'printer-status--online': !printerHasWarnings(printer) && !printerHasErrors(printer) && printerHasProxy(printer) && !printerNotAvailable(printer),
                            'printer-status--warning': printerHasWarnings(printer) && !printerHasErrors(printer) && printerHasProxy(printer) && !printerNotAvailable(printer),
                            'printer-status--offline': (printerHasErrors(printer) && printerHasProxy(printer)) || printerNotAvailable(printer),
                            'printer-status--undefined': !printerHasProxy(printer)}">
                </span> -->


                <span *ngIf="!printer.embedded.status.pollStatus">
                  <span class="status-text-placement" *ngIf="printer.embedded.status.state !== 'ERROR'">
                    {{"ONLINE" | translate}}
                  </span>
                  <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'ERROR'">
                    {{"OFFLINE" | translate}}
                  </span>
                </span>
                <span class="" *ngIf="printer.embedded.status.pollStatus">
                  <span>
                    <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'READY'">{{'Ready' | translate}}</span>
                    <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'WARNING'">{{'WARNING' | translate}}</span>
                    <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'ERROR'">{{'ERROR' | translate}}</span>
                    <span class="status-text-placement" *ngIf="printer.embedded.status.state === 'UNAVAILABLE'">{{'OFFLINE' | translate}}</span>
                    <!-- <span class="status-text-placement" *ngIf="!printerHasErrors(printer) && !printerHasWarnings(printer) && !printerNotAvailable(printer)">{{'Ready' | translate}}</span>
                    <span class="status-text-placement" *ngIf="!printerHasErrors(printer) && printerHasWarnings(printer) && !printerNotAvailable(printer)">{{'WARNING' | translate}}</span>
                    <span class="status-text-placement" *ngIf="printerHasErrors(printer) || printerNotAvailable(printer)">{{'ERROR' | translate}}</span> -->
                  </span>
                </span>
              </div>
            </div>

            <div class="print__printer-id"
            *ngIf="printer && printer.type === 'FREEDOM'"
            (click)="presentModal($event, printer, 'printerInfo', false, null)"
            tappable>
              <div class="print__printer-id__text">{{printer.name}}</div>
              <div class="printer-status printer-status--freedom"></div>
              <span class="status-text-placement">{{"Ready" | translate}}</span>
            </div>

            <!-- <span class="unavailable-printer" *ngIf="printer.type !== 'FREEDOM' && (!printerHasProxy(printer) || printerNotAvailable(printer))">{{'PrinterIsUnavailable' | translate }}</span> -->
          </span>

          <span class="select-all-container">
            <span class="select-all-label">{{"SelectAll"| translate}}</span>
            <ion-item class="printer__header__select-toggle">
              <ion-toggle
              [(ngModel)]="printer.checked"
              [disabled]="!printer.options.allowToPrint"
              (ngModelChange)="selectAllToggle(printer)">
              </ion-toggle>
            </ion-item>
          </span>

        </div>
        <div class="list__heading__printer-info"
          *ngIf="printer && printer.type !== 'FREEDOM' || printer.location !== null">
          <div class="print__printer-description" *ngIf="printer.type !== 'FREEDOM'">{{printer.name}}</div>
          <div class="print__printer-description">{{printer.location}}</div>
        </div>

      </div>
      <!-- PrintJob -->
      <!-- *ngFor="let printJob of printJobList | orderBy : printJobsPageType === 'PrintJobsPage' ? 'submitTime' : 'deletedTime' : 'desc'"> -->
      <!-- *ngFor="let printJob of printJobList | orderBy : printJobsPageType === 'PrintJobsPage' ? 'submitTime' : 'deletedTime' : 'desc' | printjobbyprinter : printer.links.self"> -->
      <div class="list-content">
        <div class="list-row list__item list__item--clickable"
        [ngClass]="{'list__item--active': printJob.options.isSelected, 'print-job-unavailable': printJob.options.resourceErrors.length !== 0}"
        *ngFor="let printJob of filteredPrintJobs(printJobList, printer.links.self)">
            <button class="list-cell actionbutton" (click)="selectPrintJob(printJob, printer)">
              <span class="list__item-title">{{printJob.jobName}}</span>
              <div class="list__item-description">
                <div>
                  {{printJob.pageCount}}
                  <span *ngIf="printJob.pageCount === 1">{{"Page" | translate}}</span>
                  <span *ngIf="printJob.pageCount > 1">{{"Pages" | translate}}</span>
                  <span>&nbsp;&nbsp;</span>
                  <div *ngIf="printJobsPageType === 'PrintJobsPage'">
                    <span am-time-ago="printJob.submitTime">{{printJob.submitTime | amLocale: currentLanguage | amTimeAgo}}</span>
                    <!-- <span>{{ printJob.submitTime | relativeTime }}</span> -->
                  </div>
                  <div *ngIf="printJobsPageType !== 'PrintJobsPage'">
                    <span am-time-ago="printJob.submitTime">{{printJob.deletedTime | amLocale: currentLanguage | amTimeAgo}}</span>
                    <!-- <span>{{ printJob.deletedTime | relativeTime }}</span> -->
                    </div>
                </div>

                <div *ngIf="printJob.options.printJobStatus.showStatusInfo && printJob.links.ongoingRelease.length === 0">
                  <span class="loader loader--sm loader--compact col-xs-4 loader--spaceless"
                      *ngIf="printJob.options.printJobStatus.showLoader">
                    <span></span>
                  </span>
                  <span [ngClass]="{'col-xs-8': printJob.options.printJobStatus.showLoader}">
                      <span *ngIf="printJob.options.printJobStatus.isSuccess">
                          <i class="icon-check text-success"></i>
                      </span>
                      <span *ngIf="printJob.options.printJobStatus.isError">
                        <i class="icon-close text-danger"></i>
                      </span>
                      {{printJob.jobState | translate}}&nbsp;
                  </span>
                </div>
                <div *ngIf="printJob.deletedTime && !printJob.links.finishedRelease && !printJob.options.printJobStatus.showStatusInfo">
                  {{"Deleted" | translate}}
                </div>
                <!-- <div *ngIf="printJob.deletedTime">
                  <div class="resource-error-text">
                    <i class="icon-close text-danger"></i>
                    {{"Deleted" | translate}}
                  </div>
                </div> -->
                <div *ngIf="printJob.options.resourceErrors.length !== 0">
                  <div class="resource-error-text" *ngFor="let error of printJob.options.resourceErrors">
                    <i class="icon-close text-danger"></i>
                    <span>{{error.type | translate}}</span>
                  </div>
                </div>
                <div *ngIf="printJob.links.ongoingRelease.length > 0">
                  <span class="loader loader--sm loader--compact col-xs-4 loader--spaceless">
                    <span></span>
                  </span>
                  <span class="ml10" *ngIf="printJob.links.ongoingRelease.length === 1">
                    {{"ReleaseInProgress" | translate}}
                  </span>
                  <span class="ml10" *ngIf="printJob.links.ongoingRelease.length > 1">
                    {{printJob.links.ongoingRelease.length}} {{"ReleasesInProgress" | translate}}
                  </span>
                </div>

              </div>
            </button>
            <button class="btn-history mr10" (click)="goToReleaseHistoryPage()" *ngIf="printJob.links.ongoingRelease.length > 0">
              <i class="icon-history"></i>
            </button>
            <button class="list-cell"
              (click)="presentPopover($event, 'printJobMenu', printJob)">
              <i class="icon-overflow-vert"></i>
            </button>
        </div>
      </div>

    </div>
  </div>

  <div class="qr-overlay-container" *ngIf="showTransparentBody">
    <!-- <button class="stop-qr-scanner" [ngClass]="{'ios': platformIsIos}" (click)="cancelQRScanner()"> -->
    <button class="stop-qr-scanner" [ngClass]="{'ios': platformIsIos}" (click)="cancelQRScanner()">
      <i class="icon-close"></i>
    </button>
    <div class="qr-focus" [ngClass]="{'qr-warning': invalidQRCode}">
      <div class="qr-msg-container">
        <div class="size70" *ngIf="!invalidQRCode">{{'ScanQR' | translate}}</div>
        <div class="size70" *ngIf="invalidQRCode">{{'InvalidQRCode' | translate}}</div>
        <div class="size120"><i class="icon-qr-code-2"></i></div>
      </div>
      <div class="release-all" *ngIf="selectedPrintJobs.length === 0">{{'PrintAllMatchingDocuments' | translate}}</div>
    </div>
    <div class="qr-documents-position" >
      <div *ngFor="let printJob of selectedPrintJobs">
        <div class="qr-documents-container">
          <div class="qr-documents">
            <i class="icon-docs mr5"></i>
            <span>
              {{printJob.jobName}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</ion-content>
<!-- Page actions -->
<div class="page-actions pr20 pl20 z1000"
  [ngClass]="{'page-actions--hidden': selectedPrintJobs.length === 0 || scanning}">

  <div class="page-actions__section">

    <!-- printAnywhere no secure print level -->
    <div class="print__print-anywhere container" *ngIf="printAnywhere && !securePrintLevel && !scanning">

      <div class="print__choose-printer">
        <button class="print__choose-printer__btn" *ngIf="!lastUsedAnywhereQueue" (click)="favoriteQueueUrls.length === 0 ? showPrinterListPage() : presentPopover($event, 'select-printer-menu', printerList)">
            <div class="print__printer-description">{{"SelectPrinter" | translate}}</div>
        </button>

        <!-- last used queue -->
        <button class="print__choose-printer__btn" *ngIf="lastUsedAnywhereQueue" (click)="presentPopover($event, 'select-printer-menu', printerList)">
          <div class="print__choose-printer__label" *ngIf="!printerAccessError">{{"Printer" | translate}}</div>
          <div class="print__choose-printer__label" *ngIf="releaseNotPossible">{{"RELEASE_NOT_POSSIBLE" | translate | stringular: ''}}</div>
          <div class="print__choose-printer__label" *ngIf="noProxiesForPrinter && !releaseNotPossible">{{"PrinterUnavailable" | translate | stringular: ''}}</div>
          <div class="print__choose-printer__label" *ngIf="printerInErrorState && printerOffline && !noProxiesForPrinter && !releaseNotPossible">{{"OFFLINE" | translate | stringular: ''}}</div>
          <div class="print__choose-printer__label" *ngIf="printerInErrorState && !printerOffline && !noProxiesForPrinter && !releaseNotPossible">{{"OutOfOrder" | translate | stringular: ''}}</div>
          <div class="print__current-printer">
            <div>
              <span class="print__printer-id">{{lastUsedAnywhereQueue.signId}}</span>
              <span class="printer-status"
              [ngClass]="{
              'printer-status--online': selectedPrinter.embedded.status.state === 'READY',
              'printer-status--warning': selectedPrinter.embedded.status.state === 'WARNING',
              'printer-status--offline': selectedPrinter.embedded.status.state === 'ERROR',
              'printer-status--undefined': selectedPrinter.embedded.status.state === 'UNAVAILABLE'}">

              </span>
              <!-- <span class="printer-status"
              [ngClass]="{'printer-status--online': !printerHasWarnings(selectedPrinter) && !printerHasErrors(selectedPrinter) && printerHasProxy(selectedPrinter) && !printerNotAvailable(selectedPrinter),
              'printer-status--warning': printerHasWarnings(selectedPrinter) && !printerHasErrors(selectedPrinter) && printerHasProxy(selectedPrinter) && !printerNotAvailable(selectedPrinter),
              'printer-status--offline': (printerHasErrors(selectedPrinter) && printerHasProxy(selectedPrinter)) || printerNotAvailable(selectedPrinter),
              'printer-status--undefined': !printerHasProxy(selectedPrinter)}">

              </span> -->
              <span class="print__printer-description">
                {{lastUsedAnywhereQueue.name}}
              </span>
            </div>
          </div>
        </button>
      </div>

      <div class="print-button-container">
        <button class="print__print-btn btn qr-scanner" *ngIf="isCordova"
          (click)="startScanning($event, 'showScanningMethods')"
        [disabled]="debouncerRunning">
          {{ 'Scan' | translate }}
        </button>
        <button class="print__print-btn btn"
          (click)="print()"
          [disabled]="!lastUsedAnywhereQueue || unavailablePrintJobs || debouncerRunning || releaseNotPossible">
          {{ "Print" | translate }}
        </button>
      </div>
    </div> <!-- /printAnywhere no secure print level -->

    <!-- secure print level QR on Cordova -->
    <div class="print__print-later container scanning-button" *ngIf="securePrintLevel && isCordova && !scanning && securePrintLevel !== 'ON_DEVICE_RELEASE'">
      <button class="print__print-btn btn width60"
        (click)="startScanning($event, 'showScanningMethods')"
        [disabled]="debouncerRunning || checkActiveSecureLevel()">
        {{ 'Scan' | translate }}
      </button>
    </div>

    <!-- Print later no secure print level -->
    <div class="print__print-later container" *ngIf="!printAnywhere && !securePrintLevel && !scanning">
      <button class="print__print-btn btn"
        (click)="print()"
        [disabled]="unavailablePrintJobs || debouncerRunning || releaseNotPossible"
        ion-button>
        {{ "Print" | translate }}
      </button>
    </div>

    <!-- Secure print level on webapp -->
    <div class="print__print-later container" *ngIf="securePrintLevel && !isCordova">
      <button class="print__print-btn btn disabled" ion-button>
        {{ "Print" | translate }}
      </button>
    </div>

  </div>
</div>
